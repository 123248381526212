/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addTodo = /* GraphQL */ `
  mutation AddTodo(
    $id: ID!
    $name: String
    $description: String
    $priority: Int
  ) {
    addTodo(
      id: $id
      name: $name
      description: $description
      priority: $priority
    ) {
      id
      name
      description
      priority
    }
  }
`;
export const loginWebjs = /* GraphQL */ `
  mutation LoginWebjs($name: String, $pass: String, $domain: String) {
    LoginWebjs(name: $name, pass: $pass, domain: $domain) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const appvmedisreactjsb669b0ff = /* GraphQL */ `
  mutation Appvmedisreactjsb669b0ff($token: String) {
    Appvmedisreactjsb669b0ff(token: $token) {
      statusCode
      body
      events
    }
  }
`;
export const mInitialDataSync = /* GraphQL */ `
  mutation MInitialDataSync($pin: String, $key: String) {
    mInitialDataSync(pin: $pin, key: $key) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncAccount = /* GraphQL */ `
  mutation MSyncAccount($input: mSyncInput) {
    mSyncAccount(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncDoctor = /* GraphQL */ `
  mutation MSyncDoctor($input: mSyncInput) {
    mSyncDoctor(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncWareHouse = /* GraphQL */ `
  mutation MSyncWareHouse($input: mSyncInput) {
    mSyncWareHouse(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncJadShf = /* GraphQL */ `
  mutation MSyncJadShf($input: mSyncInput) {
    mSyncJadShf(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncItems = /* GraphQL */ `
  mutation MSyncItems($input: mSyncInput) {
    mSyncItems(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncItemsPackage = /* GraphQL */ `
  mutation MSyncItemsPackage($input: mSyncInput) {
    mSyncItemsPackage(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncPatient = /* GraphQL */ `
  mutation MSyncPatient($input: mSyncInput) {
    mSyncPatient(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncUserConfig = /* GraphQL */ `
  mutation MSyncUserConfig($input: mSyncInput) {
    mSyncUserConfig(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncAppConfig = /* GraphQL */ `
  mutation MSyncAppConfig($input: mSyncInput) {
    mSyncAppConfig(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncGroup = /* GraphQL */ `
  mutation MSyncGroup($input: mSyncInput) {
    mSyncGroup(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncGroupAccess = /* GraphQL */ `
  mutation MSyncGroupAccess($input: mSyncInput) {
    mSyncGroupAccess(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncCashierUsers = /* GraphQL */ `
  mutation MSyncCashierUsers($input: mSyncInput) {
    mSyncCashierUsers(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSyncCategory = /* GraphQL */ `
  mutation MSyncCategory($input: mSyncInput) {
    mSyncCategory(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpSyncDoctor = /* GraphQL */ `
  mutation MUpSyncDoctor($input: mSyncInput) {
    mUpSyncDoctor(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpSyncPatient = /* GraphQL */ `
  mutation MUpSyncPatient($input: mSyncInput) {
    mUpSyncPatient(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpSyncItemSale = /* GraphQL */ `
  mutation MUpSyncItemSale($input: mSyncInput) {
    mUpSyncItemSale(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpSyncShift = /* GraphQL */ `
  mutation MUpSyncShift($input: mSyncInput) {
    mUpSyncShift(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mLogV2 = /* GraphQL */ `
  mutation MLogV2($input: mSyncInput) {
    mLogV2(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const createTodo2 = /* GraphQL */ `
  mutation CreateTodo2(
    $input: CreateTodo2Input!
    $condition: ModelTodo2ConditionInput
  ) {
    createTodo2(input: $input, condition: $condition) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo2 = /* GraphQL */ `
  mutation UpdateTodo2(
    $input: UpdateTodo2Input!
    $condition: ModelTodo2ConditionInput
  ) {
    updateTodo2(input: $input, condition: $condition) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo2 = /* GraphQL */ `
  mutation DeleteTodo2(
    $input: DeleteTodo2Input!
    $condition: ModelTodo2ConditionInput
  ) {
    deleteTodo2(input: $input, condition: $condition) {
      id
      name
      description
      priority
      createdAt
      updatedAt
    }
  }
`;
export const createMasPasien = /* GraphQL */ `
  mutation CreateMasPasien(
    $input: CreateMasPasienInput!
    $condition: ModelmasPasienConditionInput
  ) {
    createMasPasien(input: $input, condition: $condition) {
      id
      pasid
      pasrm
      pasnama
      pasemail
      katid
      pasgoldarah
      pasjk
      pasalamat
      paskota
      pastelp
      paspekerjaan
      passtatusnikah
      pasnokk
      pasnoktp
      pasibu
      pasayah
      pastglreg
      pastgllahir
      pasaktif
      pasalergi
      pasbbm
      androidid
      pasparentid
      idvmedis
      createdAt
      updatedAt
    }
  }
`;
export const updateMasPasien = /* GraphQL */ `
  mutation UpdateMasPasien(
    $input: UpdateMasPasienInput!
    $condition: ModelmasPasienConditionInput
  ) {
    updateMasPasien(input: $input, condition: $condition) {
      id
      pasid
      pasrm
      pasnama
      pasemail
      katid
      pasgoldarah
      pasjk
      pasalamat
      paskota
      pastelp
      paspekerjaan
      passtatusnikah
      pasnokk
      pasnoktp
      pasibu
      pasayah
      pastglreg
      pastgllahir
      pasaktif
      pasalergi
      pasbbm
      androidid
      pasparentid
      idvmedis
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasPasien = /* GraphQL */ `
  mutation DeleteMasPasien(
    $input: DeleteMasPasienInput!
    $condition: ModelmasPasienConditionInput
  ) {
    deleteMasPasien(input: $input, condition: $condition) {
      id
      pasid
      pasrm
      pasnama
      pasemail
      katid
      pasgoldarah
      pasjk
      pasalamat
      paskota
      pastelp
      paspekerjaan
      passtatusnikah
      pasnokk
      pasnoktp
      pasibu
      pasayah
      pastglreg
      pastgllahir
      pasaktif
      pasalergi
      pasbbm
      androidid
      pasparentid
      idvmedis
      createdAt
      updatedAt
    }
  }
`;
export const createMasDokter = /* GraphQL */ `
  mutation CreateMasDokter(
    $input: CreateMasDokterInput!
    $condition: ModelmasDokterConditionInput
  ) {
    createMasDokter(input: $input, condition: $condition) {
      id
      dokid
      doknama
      dokspesialis
      dokalamat
      dokkota
      doktelp
      dokemail
      dokaktif
      dokjenis
      doktglreg
      doktglmulai
      dokjasa
      dokfoto
      dokidgen
      doknostr
      createdAt
      updatedAt
    }
  }
`;
export const updateMasDokter = /* GraphQL */ `
  mutation UpdateMasDokter(
    $input: UpdateMasDokterInput!
    $condition: ModelmasDokterConditionInput
  ) {
    updateMasDokter(input: $input, condition: $condition) {
      id
      dokid
      doknama
      dokspesialis
      dokalamat
      dokkota
      doktelp
      dokemail
      dokaktif
      dokjenis
      doktglreg
      doktglmulai
      dokjasa
      dokfoto
      dokidgen
      doknostr
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasDokter = /* GraphQL */ `
  mutation DeleteMasDokter(
    $input: DeleteMasDokterInput!
    $condition: ModelmasDokterConditionInput
  ) {
    deleteMasDokter(input: $input, condition: $condition) {
      id
      dokid
      doknama
      dokspesialis
      dokalamat
      dokkota
      doktelp
      dokemail
      dokaktif
      dokjenis
      doktglreg
      doktglmulai
      dokjasa
      dokfoto
      dokidgen
      doknostr
      createdAt
      updatedAt
    }
  }
`;
export const createMasPoli = /* GraphQL */ `
  mutation CreateMasPoli(
    $input: CreateMasPoliInput!
    $condition: ModelmasPoliConditionInput
  ) {
    createMasPoli(input: $input, condition: $condition) {
      id
      polid
      polnama
      polket
      polaktif
      createdAt
      updatedAt
    }
  }
`;
export const updateMasPoli = /* GraphQL */ `
  mutation UpdateMasPoli(
    $input: UpdateMasPoliInput!
    $condition: ModelmasPoliConditionInput
  ) {
    updateMasPoli(input: $input, condition: $condition) {
      id
      polid
      polnama
      polket
      polaktif
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasPoli = /* GraphQL */ `
  mutation DeleteMasPoli(
    $input: DeleteMasPoliInput!
    $condition: ModelmasPoliConditionInput
  ) {
    deleteMasPoli(input: $input, condition: $condition) {
      id
      polid
      polnama
      polket
      polaktif
      createdAt
      updatedAt
    }
  }
`;
export const createIncrementId = /* GraphQL */ `
  mutation CreateIncrementId(
    $input: CreateIncrementIdInput!
    $condition: ModelIncrementIdConditionInput
  ) {
    createIncrementId(input: $input, condition: $condition) {
      id
      name
      code
      refid
      createdAt
      updatedAt
    }
  }
`;
export const updateIncrementId = /* GraphQL */ `
  mutation UpdateIncrementId(
    $input: UpdateIncrementIdInput!
    $condition: ModelIncrementIdConditionInput
  ) {
    updateIncrementId(input: $input, condition: $condition) {
      id
      name
      code
      refid
      createdAt
      updatedAt
    }
  }
`;
export const deleteIncrementId = /* GraphQL */ `
  mutation DeleteIncrementId(
    $input: DeleteIncrementIdInput!
    $condition: ModelIncrementIdConditionInput
  ) {
    deleteIncrementId(input: $input, condition: $condition) {
      id
      name
      code
      refid
      createdAt
      updatedAt
    }
  }
`;
export const createDeviceId = /* GraphQL */ `
  mutation CreateDeviceId(
    $input: CreateDeviceIdInput!
    $condition: ModelDeviceIdConditionInput
  ) {
    createDeviceId(input: $input, condition: $condition) {
      id
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateDeviceId = /* GraphQL */ `
  mutation UpdateDeviceId(
    $input: UpdateDeviceIdInput!
    $condition: ModelDeviceIdConditionInput
  ) {
    updateDeviceId(input: $input, condition: $condition) {
      id
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteDeviceId = /* GraphQL */ `
  mutation DeleteDeviceId(
    $input: DeleteDeviceIdInput!
    $condition: ModelDeviceIdConditionInput
  ) {
    deleteDeviceId(input: $input, condition: $condition) {
      id
      code
      status
      createdAt
      updatedAt
    }
  }
`;
export const createTblVersi = /* GraphQL */ `
  mutation CreateTblVersi(
    $input: CreateTblVersiInput!
    $condition: ModelTblVersiConditionInput
  ) {
    createTblVersi(input: $input, condition: $condition) {
      id
      version
      verstatus
      vername
      vernote
      createdAt
      updatedAt
    }
  }
`;
export const updateTblVersi = /* GraphQL */ `
  mutation UpdateTblVersi(
    $input: UpdateTblVersiInput!
    $condition: ModelTblVersiConditionInput
  ) {
    updateTblVersi(input: $input, condition: $condition) {
      id
      version
      verstatus
      vername
      vernote
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblVersi = /* GraphQL */ `
  mutation DeleteTblVersi(
    $input: DeleteTblVersiInput!
    $condition: ModelTblVersiConditionInput
  ) {
    deleteTblVersi(input: $input, condition: $condition) {
      id
      version
      verstatus
      vername
      vernote
      createdAt
      updatedAt
    }
  }
`;
export const createTblGroup = /* GraphQL */ `
  mutation CreateTblGroup(
    $input: CreateTblGroupInput!
    $condition: ModelTblGroupConditionInput
  ) {
    createTblGroup(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tgrpid
      tgrpname
      tgrpdisc
      createdAt
      updatedAt
    }
  }
`;
export const updateTblGroup = /* GraphQL */ `
  mutation UpdateTblGroup(
    $input: UpdateTblGroupInput!
    $condition: ModelTblGroupConditionInput
  ) {
    updateTblGroup(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tgrpid
      tgrpname
      tgrpdisc
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblGroup = /* GraphQL */ `
  mutation DeleteTblGroup(
    $input: DeleteTblGroupInput!
    $condition: ModelTblGroupConditionInput
  ) {
    deleteTblGroup(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tgrpid
      tgrpname
      tgrpdisc
      createdAt
      updatedAt
    }
  }
`;
export const createTblGroupAccess = /* GraphQL */ `
  mutation CreateTblGroupAccess(
    $input: CreateTblGroupAccessInput!
    $condition: ModelTblGroupAccessConditionInput
  ) {
    createTblGroupAccess(input: $input, condition: $condition) {
      id
      TblGroupID
      TblGroup {
        id
        keypinID
        keypin
        tgrpid
        tgrpname
        tgrpdisc
        createdAt
        updatedAt
      }
      keypinID
      keypin
      tgacid
      tgrpid
      tgrpmenu
      createdAt
      updatedAt
    }
  }
`;
export const updateTblGroupAccess = /* GraphQL */ `
  mutation UpdateTblGroupAccess(
    $input: UpdateTblGroupAccessInput!
    $condition: ModelTblGroupAccessConditionInput
  ) {
    updateTblGroupAccess(input: $input, condition: $condition) {
      id
      TblGroupID
      TblGroup {
        id
        keypinID
        keypin
        tgrpid
        tgrpname
        tgrpdisc
        createdAt
        updatedAt
      }
      keypinID
      keypin
      tgacid
      tgrpid
      tgrpmenu
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblGroupAccess = /* GraphQL */ `
  mutation DeleteTblGroupAccess(
    $input: DeleteTblGroupAccessInput!
    $condition: ModelTblGroupAccessConditionInput
  ) {
    deleteTblGroupAccess(input: $input, condition: $condition) {
      id
      TblGroupID
      TblGroup {
        id
        keypinID
        keypin
        tgrpid
        tgrpname
        tgrpdisc
        createdAt
        updatedAt
      }
      keypinID
      keypin
      tgacid
      tgrpid
      tgrpmenu
      createdAt
      updatedAt
    }
  }
`;
export const createGetToken = /* GraphQL */ `
  mutation CreateGetToken(
    $input: CreateGetTokenInput!
    $condition: ModelgetTokenConditionInput
  ) {
    createGetToken(input: $input, condition: $condition) {
      id
      app_id
      nama_lengkap
      username
      password
      password_hash
      domain
      user_id
      kl_id
      kl_nama
      alamat
      keterangan
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateGetToken = /* GraphQL */ `
  mutation UpdateGetToken(
    $input: UpdateGetTokenInput!
    $condition: ModelgetTokenConditionInput
  ) {
    updateGetToken(input: $input, condition: $condition) {
      id
      app_id
      nama_lengkap
      username
      password
      password_hash
      domain
      user_id
      kl_id
      kl_nama
      alamat
      keterangan
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteGetToken = /* GraphQL */ `
  mutation DeleteGetToken(
    $input: DeleteGetTokenInput!
    $condition: ModelgetTokenConditionInput
  ) {
    deleteGetToken(input: $input, condition: $condition) {
      id
      app_id
      nama_lengkap
      username
      password
      password_hash
      domain
      user_id
      kl_id
      kl_nama
      alamat
      keterangan
      status
      createdAt
      updatedAt
    }
  }
`;
export const createTblIdentity = /* GraphQL */ `
  mutation CreateTblIdentity(
    $input: CreateTblIdentityInput!
    $condition: ModelTblIdentityConditionInput
  ) {
    createTblIdentity(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tidaponame
      tidapono
      tidapoadd
      tidapocity
      tidapophone
      tidapomail
      tidapoweb
      tidapoimg
      tidaporegion
      createdAt
      updatedAt
    }
  }
`;
export const updateTblIdentity = /* GraphQL */ `
  mutation UpdateTblIdentity(
    $input: UpdateTblIdentityInput!
    $condition: ModelTblIdentityConditionInput
  ) {
    updateTblIdentity(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tidaponame
      tidapono
      tidapoadd
      tidapocity
      tidapophone
      tidapomail
      tidapoweb
      tidapoimg
      tidaporegion
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblIdentity = /* GraphQL */ `
  mutation DeleteTblIdentity(
    $input: DeleteTblIdentityInput!
    $condition: ModelTblIdentityConditionInput
  ) {
    deleteTblIdentity(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tidaponame
      tidapono
      tidapoadd
      tidapocity
      tidapophone
      tidapomail
      tidapoweb
      tidapoimg
      tidaporegion
      createdAt
      updatedAt
    }
  }
`;
export const createTblLogin = /* GraphQL */ `
  mutation CreateTblLogin(
    $input: CreateTblLoginInput!
    $condition: ModelTblLoginConditionInput
  ) {
    createTblLogin(input: $input, condition: $condition) {
      id
      keypinID
      tlogid
      keypin
      tlogsub
      tloguser
      tlogauth
      tlogname
      tlogimg
      tlogregion
      tgrpid
      createdAt
      updatedAt
    }
  }
`;
export const updateTblLogin = /* GraphQL */ `
  mutation UpdateTblLogin(
    $input: UpdateTblLoginInput!
    $condition: ModelTblLoginConditionInput
  ) {
    updateTblLogin(input: $input, condition: $condition) {
      id
      keypinID
      tlogid
      keypin
      tlogsub
      tloguser
      tlogauth
      tlogname
      tlogimg
      tlogregion
      tgrpid
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblLogin = /* GraphQL */ `
  mutation DeleteTblLogin(
    $input: DeleteTblLoginInput!
    $condition: ModelTblLoginConditionInput
  ) {
    deleteTblLogin(input: $input, condition: $condition) {
      id
      keypinID
      tlogid
      keypin
      tlogsub
      tloguser
      tlogauth
      tlogname
      tlogimg
      tlogregion
      tgrpid
      createdAt
      updatedAt
    }
  }
`;
export const createTblJadShf = /* GraphQL */ `
  mutation CreateTblJadShf(
    $input: CreateTblJadShfInput!
    $condition: ModelTblJadShfConditionInput
  ) {
    createTblJadShf(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tjsnama
      tjsid
      createdAt
      updatedAt
    }
  }
`;
export const updateTblJadShf = /* GraphQL */ `
  mutation UpdateTblJadShf(
    $input: UpdateTblJadShfInput!
    $condition: ModelTblJadShfConditionInput
  ) {
    updateTblJadShf(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tjsnama
      tjsid
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblJadShf = /* GraphQL */ `
  mutation DeleteTblJadShf(
    $input: DeleteTblJadShfInput!
    $condition: ModelTblJadShfConditionInput
  ) {
    deleteTblJadShf(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tjsnama
      tjsid
      createdAt
      updatedAt
    }
  }
`;
export const createTblShf = /* GraphQL */ `
  mutation CreateTblShf(
    $input: CreateTblShfInput!
    $condition: ModelTblShfConditionInput
  ) {
    createTblShf(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const updateTblShf = /* GraphQL */ `
  mutation UpdateTblShf(
    $input: UpdateTblShfInput!
    $condition: ModelTblShfConditionInput
  ) {
    updateTblShf(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblShf = /* GraphQL */ `
  mutation DeleteTblShf(
    $input: DeleteTblShfInput!
    $condition: ModelTblShfConditionInput
  ) {
    deleteTblShf(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const createTblShif = /* GraphQL */ `
  mutation CreateTblShif(
    $input: CreateTblShifInput!
    $condition: ModelTblShifConditionInput
  ) {
    createTblShif(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const updateTblShif = /* GraphQL */ `
  mutation UpdateTblShif(
    $input: UpdateTblShifInput!
    $condition: ModelTblShifConditionInput
  ) {
    updateTblShif(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblShif = /* GraphQL */ `
  mutation DeleteTblShif(
    $input: DeleteTblShifInput!
    $condition: ModelTblShifConditionInput
  ) {
    deleteTblShif(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tshid
      tshopen
      tshclose
      tshopenbal
      tshclosebal
      tshnote
      tlogid
      tshcloselogid
      tshclosename
      tshcode
      tshsumsales
      tshsync
      createdAt
      updatedAt
    }
  }
`;
export const createTblAppConfig = /* GraphQL */ `
  mutation CreateTblAppConfig(
    $input: CreateTblAppConfigInput!
    $condition: ModelTblAppConfigConditionInput
  ) {
    createTblAppConfig(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tacaptmemo
      tactax
      tacembalase
      tactuslah
      tacmsh
      tacetiket
      createdAt
      updatedAt
    }
  }
`;
export const updateTblAppConfig = /* GraphQL */ `
  mutation UpdateTblAppConfig(
    $input: UpdateTblAppConfigInput!
    $condition: ModelTblAppConfigConditionInput
  ) {
    updateTblAppConfig(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tacaptmemo
      tactax
      tacembalase
      tactuslah
      tacmsh
      tacetiket
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblAppConfig = /* GraphQL */ `
  mutation DeleteTblAppConfig(
    $input: DeleteTblAppConfigInput!
    $condition: ModelTblAppConfigConditionInput
  ) {
    deleteTblAppConfig(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tacaptmemo
      tactax
      tacembalase
      tactuslah
      tacmsh
      tacetiket
      createdAt
      updatedAt
    }
  }
`;
export const createTblItems = /* GraphQL */ `
  mutation CreateTblItems(
    $input: CreateTblItemsInput!
    $condition: ModelTblItemsConditionInput
  ) {
    createTblItems(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tiid
      ticode
      tiname
      ticategoryid
      ticategory
      tifactoryid
      tifactory
      tilocationid
      tilocation
      ticontent
      titype
      tistock
      tisatcount
      tisatid1
      tisatname1
      tisatidcon1
      tibarcode1
      tiprice11
      tipricedisc11
      tiprice12
      tipricedisc12
      tiprice13
      tipricedisc13
      tisatid2
      tisatname2
      tisatidcon2
      tibarcode2
      tiprice21
      tipricedisc21
      tiprice22
      tipricedisc22
      tiprice23
      tipricedisc23
      tisatid3
      tisatname3
      tisatidcon3
      tibarcode3
      tiprice31
      tipricedisc31
      tiprice32
      tipricedisc32
      tiprice33
      tipricedisc33
      tisatid4
      tisatname4
      tisatidcon4
      tibarcode4
      tiprice41
      tipricedisc41
      tiprice42
      tipricedisc42
      tiprice43
      tipricedisc43
      createdAt
      updatedAt
    }
  }
`;
export const updateTblItems = /* GraphQL */ `
  mutation UpdateTblItems(
    $input: UpdateTblItemsInput!
    $condition: ModelTblItemsConditionInput
  ) {
    updateTblItems(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tiid
      ticode
      tiname
      ticategoryid
      ticategory
      tifactoryid
      tifactory
      tilocationid
      tilocation
      ticontent
      titype
      tistock
      tisatcount
      tisatid1
      tisatname1
      tisatidcon1
      tibarcode1
      tiprice11
      tipricedisc11
      tiprice12
      tipricedisc12
      tiprice13
      tipricedisc13
      tisatid2
      tisatname2
      tisatidcon2
      tibarcode2
      tiprice21
      tipricedisc21
      tiprice22
      tipricedisc22
      tiprice23
      tipricedisc23
      tisatid3
      tisatname3
      tisatidcon3
      tibarcode3
      tiprice31
      tipricedisc31
      tiprice32
      tipricedisc32
      tiprice33
      tipricedisc33
      tisatid4
      tisatname4
      tisatidcon4
      tibarcode4
      tiprice41
      tipricedisc41
      tiprice42
      tipricedisc42
      tiprice43
      tipricedisc43
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblItems = /* GraphQL */ `
  mutation DeleteTblItems(
    $input: DeleteTblItemsInput!
    $condition: ModelTblItemsConditionInput
  ) {
    deleteTblItems(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tiid
      ticode
      tiname
      ticategoryid
      ticategory
      tifactoryid
      tifactory
      tilocationid
      tilocation
      ticontent
      titype
      tistock
      tisatcount
      tisatid1
      tisatname1
      tisatidcon1
      tibarcode1
      tiprice11
      tipricedisc11
      tiprice12
      tipricedisc12
      tiprice13
      tipricedisc13
      tisatid2
      tisatname2
      tisatidcon2
      tibarcode2
      tiprice21
      tipricedisc21
      tiprice22
      tipricedisc22
      tiprice23
      tipricedisc23
      tisatid3
      tisatname3
      tisatidcon3
      tibarcode3
      tiprice31
      tipricedisc31
      tiprice32
      tipricedisc32
      tiprice33
      tipricedisc33
      tisatid4
      tisatname4
      tisatidcon4
      tibarcode4
      tiprice41
      tipricedisc41
      tiprice42
      tipricedisc42
      tiprice43
      tipricedisc43
      createdAt
      updatedAt
    }
  }
`;
export const createLoginWebjs = /* GraphQL */ `
  mutation CreateLoginWebjs(
    $input: CreateLoginWebjsInput!
    $condition: ModelloginWebjsConditionInput
  ) {
    createLoginWebjs(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const updateLoginWebjs = /* GraphQL */ `
  mutation UpdateLoginWebjs(
    $input: UpdateLoginWebjsInput!
    $condition: ModelloginWebjsConditionInput
  ) {
    updateLoginWebjs(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const deleteLoginWebjs = /* GraphQL */ `
  mutation DeleteLoginWebjs(
    $input: DeleteLoginWebjsInput!
    $condition: ModelloginWebjsConditionInput
  ) {
    deleteLoginWebjs(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const createLoginWebjs3 = /* GraphQL */ `
  mutation CreateLoginWebjs3(
    $input: CreateLoginWebjs3Input!
    $condition: ModelloginWebjs3ConditionInput
  ) {
    createLoginWebjs3(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const updateLoginWebjs3 = /* GraphQL */ `
  mutation UpdateLoginWebjs3(
    $input: UpdateLoginWebjs3Input!
    $condition: ModelloginWebjs3ConditionInput
  ) {
    updateLoginWebjs3(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const deleteLoginWebjs3 = /* GraphQL */ `
  mutation DeleteLoginWebjs3(
    $input: DeleteLoginWebjs3Input!
    $condition: ModelloginWebjs3ConditionInput
  ) {
    deleteLoginWebjs3(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const createLoginWebjs4 = /* GraphQL */ `
  mutation CreateLoginWebjs4(
    $input: CreateLoginWebjs4Input!
    $condition: ModelloginWebjs4ConditionInput
  ) {
    createLoginWebjs4(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const updateLoginWebjs4 = /* GraphQL */ `
  mutation UpdateLoginWebjs4(
    $input: UpdateLoginWebjs4Input!
    $condition: ModelloginWebjs4ConditionInput
  ) {
    updateLoginWebjs4(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const deleteLoginWebjs4 = /* GraphQL */ `
  mutation DeleteLoginWebjs4(
    $input: DeleteLoginWebjs4Input!
    $condition: ModelloginWebjs4ConditionInput
  ) {
    deleteLoginWebjs4(input: $input, condition: $condition) {
      id
      statusCode
      body
      events
      createdAt
      updatedAt
    }
  }
`;
export const createTblSync = /* GraphQL */ `
  mutation CreateTblSync(
    $input: CreateTblSyncInput!
    $condition: ModelTblSyncConditionInput
  ) {
    createTblSync(input: $input, condition: $condition) {
      id
      keypin
      keypinID
      initdownload
      statusdownload
      errormsgdownload
      tsylastdownload
      tsylastdownloadid
      initupload
      statusupload
      errormsgupload
      tsylastupload
      tsylastuploadid
      flaglocalid
      createdAt
      updatedAt
    }
  }
`;
export const updateTblSync = /* GraphQL */ `
  mutation UpdateTblSync(
    $input: UpdateTblSyncInput!
    $condition: ModelTblSyncConditionInput
  ) {
    updateTblSync(input: $input, condition: $condition) {
      id
      keypin
      keypinID
      initdownload
      statusdownload
      errormsgdownload
      tsylastdownload
      tsylastdownloadid
      initupload
      statusupload
      errormsgupload
      tsylastupload
      tsylastuploadid
      flaglocalid
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblSync = /* GraphQL */ `
  mutation DeleteTblSync(
    $input: DeleteTblSyncInput!
    $condition: ModelTblSyncConditionInput
  ) {
    deleteTblSync(input: $input, condition: $condition) {
      id
      keypin
      keypinID
      initdownload
      statusdownload
      errormsgdownload
      tsylastdownload
      tsylastdownloadid
      initupload
      statusupload
      errormsgupload
      tsylastupload
      tsylastuploadid
      flaglocalid
      createdAt
      updatedAt
    }
  }
`;
export const createTblAccount = /* GraphQL */ `
  mutation CreateTblAccount(
    $input: CreateTblAccountInput!
    $condition: ModelTblAccountConditionInput
  ) {
    createTblAccount(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      taccgroup
      taccpost
      tacccode
      taccname
      createdAt
      updatedAt
    }
  }
`;
export const updateTblAccount = /* GraphQL */ `
  mutation UpdateTblAccount(
    $input: UpdateTblAccountInput!
    $condition: ModelTblAccountConditionInput
  ) {
    updateTblAccount(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      taccgroup
      taccpost
      tacccode
      taccname
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblAccount = /* GraphQL */ `
  mutation DeleteTblAccount(
    $input: DeleteTblAccountInput!
    $condition: ModelTblAccountConditionInput
  ) {
    deleteTblAccount(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      taccgroup
      taccpost
      tacccode
      taccname
      createdAt
      updatedAt
    }
  }
`;
export const createTblDoctor = /* GraphQL */ `
  mutation CreateTblDoctor(
    $input: CreateTblDoctorInput!
    $condition: ModelTblDoctorConditionInput
  ) {
    createTblDoctor(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tdocid
      tdocname
      tdocspes
      tdocaddres
      tdocdatestart
      tdoccity
      tdocphone
      tdocemail
      tdocactive
      tdoctype
      tdocdatereg
      tdocshare
      tdocstrno
      tdocsync
      createdAt
      updatedAt
    }
  }
`;
export const updateTblDoctor = /* GraphQL */ `
  mutation UpdateTblDoctor(
    $input: UpdateTblDoctorInput!
    $condition: ModelTblDoctorConditionInput
  ) {
    updateTblDoctor(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tdocid
      tdocname
      tdocspes
      tdocaddres
      tdocdatestart
      tdoccity
      tdocphone
      tdocemail
      tdocactive
      tdoctype
      tdocdatereg
      tdocshare
      tdocstrno
      tdocsync
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblDoctor = /* GraphQL */ `
  mutation DeleteTblDoctor(
    $input: DeleteTblDoctorInput!
    $condition: ModelTblDoctorConditionInput
  ) {
    deleteTblDoctor(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tdocid
      tdocname
      tdocspes
      tdocaddres
      tdocdatestart
      tdoccity
      tdocphone
      tdocemail
      tdocactive
      tdoctype
      tdocdatereg
      tdocshare
      tdocstrno
      tdocsync
      createdAt
      updatedAt
    }
  }
`;
export const createTblWareHouse = /* GraphQL */ `
  mutation CreateTblWareHouse(
    $input: CreateTblWareHouseInput!
    $condition: ModelTblWareHouseConditionInput
  ) {
    createTblWareHouse(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tiid
      twhid
      twhname
      twhstock
      twhlocation
      createdAt
      updatedAt
    }
  }
`;
export const updateTblWareHouse = /* GraphQL */ `
  mutation UpdateTblWareHouse(
    $input: UpdateTblWareHouseInput!
    $condition: ModelTblWareHouseConditionInput
  ) {
    updateTblWareHouse(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tiid
      twhid
      twhname
      twhstock
      twhlocation
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblWareHouse = /* GraphQL */ `
  mutation DeleteTblWareHouse(
    $input: DeleteTblWareHouseInput!
    $condition: ModelTblWareHouseConditionInput
  ) {
    deleteTblWareHouse(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tiid
      twhid
      twhname
      twhstock
      twhlocation
      createdAt
      updatedAt
    }
  }
`;
export const createTblItemsPackage = /* GraphQL */ `
  mutation CreateTblItemsPackage(
    $input: CreateTblItemsPackageInput!
    $condition: ModelTblItemsPackageConditionInput
  ) {
    createTblItemsPackage(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tpid
      titpid
      tiid
      titpqty
      titpsatid
      titpsatname
      titpsatidcon
      createdAt
      updatedAt
    }
  }
`;
export const updateTblItemsPackage = /* GraphQL */ `
  mutation UpdateTblItemsPackage(
    $input: UpdateTblItemsPackageInput!
    $condition: ModelTblItemsPackageConditionInput
  ) {
    updateTblItemsPackage(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tpid
      titpid
      tiid
      titpqty
      titpsatid
      titpsatname
      titpsatidcon
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblItemsPackage = /* GraphQL */ `
  mutation DeleteTblItemsPackage(
    $input: DeleteTblItemsPackageInput!
    $condition: ModelTblItemsPackageConditionInput
  ) {
    deleteTblItemsPackage(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tpid
      titpid
      tiid
      titpqty
      titpsatid
      titpsatname
      titpsatidcon
      createdAt
      updatedAt
    }
  }
`;
export const createTblPatient = /* GraphQL */ `
  mutation CreateTblPatient(
    $input: CreateTblPatientInput!
    $condition: ModelTblPatientConditionInput
  ) {
    createTblPatient(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tpatid
      tpatcat
      tpatcode
      tpatname
      tpatgender
      tpatborn
      tpataddress
      tpatmother
      tpatphone
      tpatblood
      tpatcity
      tpatmarital
      tpatjob
      tpatfather
      tpatkkno
      tpatregno
      tpatemail
      tpataller
      tpatactive
      tpatsync
      createdAt
      updatedAt
    }
  }
`;
export const updateTblPatient = /* GraphQL */ `
  mutation UpdateTblPatient(
    $input: UpdateTblPatientInput!
    $condition: ModelTblPatientConditionInput
  ) {
    updateTblPatient(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tpatid
      tpatcat
      tpatcode
      tpatname
      tpatgender
      tpatborn
      tpataddress
      tpatmother
      tpatphone
      tpatblood
      tpatcity
      tpatmarital
      tpatjob
      tpatfather
      tpatkkno
      tpatregno
      tpatemail
      tpataller
      tpatactive
      tpatsync
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblPatient = /* GraphQL */ `
  mutation DeleteTblPatient(
    $input: DeleteTblPatientInput!
    $condition: ModelTblPatientConditionInput
  ) {
    deleteTblPatient(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tpatid
      tpatcat
      tpatcode
      tpatname
      tpatgender
      tpatborn
      tpataddress
      tpatmother
      tpatphone
      tpatblood
      tpatcity
      tpatmarital
      tpatjob
      tpatfather
      tpatkkno
      tpatregno
      tpatemail
      tpataller
      tpatactive
      tpatsync
      createdAt
      updatedAt
    }
  }
`;
export const createTblCategory = /* GraphQL */ `
  mutation CreateTblCategory(
    $input: CreateTblCategoryInput!
    $condition: ModelTblCategoryConditionInput
  ) {
    createTblCategory(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tcid
      tcname
      tcrate
      tcnote
      tcactive
      createdAt
      updatedAt
    }
  }
`;
export const updateTblCategory = /* GraphQL */ `
  mutation UpdateTblCategory(
    $input: UpdateTblCategoryInput!
    $condition: ModelTblCategoryConditionInput
  ) {
    updateTblCategory(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tcid
      tcname
      tcrate
      tcnote
      tcactive
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblCategory = /* GraphQL */ `
  mutation DeleteTblCategory(
    $input: DeleteTblCategoryInput!
    $condition: ModelTblCategoryConditionInput
  ) {
    deleteTblCategory(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tcid
      tcname
      tcrate
      tcnote
      tcactive
      createdAt
      updatedAt
    }
  }
`;
export const createTblCashierUsers = /* GraphQL */ `
  mutation CreateTblCashierUsers(
    $input: CreateTblCashierUsersInput!
    $condition: ModelTblCashierUsersConditionInput
  ) {
    createTblCashierUsers(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tcuid
      tcuname
      createdAt
      updatedAt
    }
  }
`;
export const updateTblCashierUsers = /* GraphQL */ `
  mutation UpdateTblCashierUsers(
    $input: UpdateTblCashierUsersInput!
    $condition: ModelTblCashierUsersConditionInput
  ) {
    updateTblCashierUsers(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tcuid
      tcuname
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblCashierUsers = /* GraphQL */ `
  mutation DeleteTblCashierUsers(
    $input: DeleteTblCashierUsersInput!
    $condition: ModelTblCashierUsersConditionInput
  ) {
    deleteTblCashierUsers(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tcuid
      tcuname
      createdAt
      updatedAt
    }
  }
`;
export const createTblUserConfig = /* GraphQL */ `
  mutation CreateTblUserConfig(
    $input: CreateTblUserConfigInput!
    $condition: ModelTblUserConfigConditionInput
  ) {
    createTblUserConfig(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tucid
      tlogid
      tucprinter
      tucleft1
      tucleft2
      tucleft3
      tucleft4
      tucleft5
      tucleft6
      tucleft7
      tucleft8
      tucleft9
      tucleft10
      tucleft11
      tucleft12
      tucleft13
      tucright1
      tucright2
      tucright3
      tucright4
      tucright5
      tucright6
      tucright7
      tucright8
      tucright9
      tucright10
      tucright11
      tucright12
      tucright13
      tucpromo1
      tucpromo2
      tucpromo3
      tucpromo4
      tucpromo5
      tucpromo6
      tucpromo7
      tucpromo8
      tucpromo9
      tucpromo10
      tucpromo13
      tucexpired1
      tucexpired2
      tucexpired3
      tucexpired4
      tucexpired5
      tucexpired6
      tucexpired7
      tucexpired8
      tucexpired9
      tucexpired10
      tucexpired13
      tucdetail1
      tucdetail2
      tucdetail6
      tucdetail7
      createdAt
      updatedAt
    }
  }
`;
export const updateTblUserConfig = /* GraphQL */ `
  mutation UpdateTblUserConfig(
    $input: UpdateTblUserConfigInput!
    $condition: ModelTblUserConfigConditionInput
  ) {
    updateTblUserConfig(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tucid
      tlogid
      tucprinter
      tucleft1
      tucleft2
      tucleft3
      tucleft4
      tucleft5
      tucleft6
      tucleft7
      tucleft8
      tucleft9
      tucleft10
      tucleft11
      tucleft12
      tucleft13
      tucright1
      tucright2
      tucright3
      tucright4
      tucright5
      tucright6
      tucright7
      tucright8
      tucright9
      tucright10
      tucright11
      tucright12
      tucright13
      tucpromo1
      tucpromo2
      tucpromo3
      tucpromo4
      tucpromo5
      tucpromo6
      tucpromo7
      tucpromo8
      tucpromo9
      tucpromo10
      tucpromo13
      tucexpired1
      tucexpired2
      tucexpired3
      tucexpired4
      tucexpired5
      tucexpired6
      tucexpired7
      tucexpired8
      tucexpired9
      tucexpired10
      tucexpired13
      tucdetail1
      tucdetail2
      tucdetail6
      tucdetail7
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblUserConfig = /* GraphQL */ `
  mutation DeleteTblUserConfig(
    $input: DeleteTblUserConfigInput!
    $condition: ModelTblUserConfigConditionInput
  ) {
    deleteTblUserConfig(input: $input, condition: $condition) {
      id
      keypinID
      keypin
      tucid
      tlogid
      tucprinter
      tucleft1
      tucleft2
      tucleft3
      tucleft4
      tucleft5
      tucleft6
      tucleft7
      tucleft8
      tucleft9
      tucleft10
      tucleft11
      tucleft12
      tucleft13
      tucright1
      tucright2
      tucright3
      tucright4
      tucright5
      tucright6
      tucright7
      tucright8
      tucright9
      tucright10
      tucright11
      tucright12
      tucright13
      tucpromo1
      tucpromo2
      tucpromo3
      tucpromo4
      tucpromo5
      tucpromo6
      tucpromo7
      tucpromo8
      tucpromo9
      tucpromo10
      tucpromo13
      tucexpired1
      tucexpired2
      tucexpired3
      tucexpired4
      tucexpired5
      tucexpired6
      tucexpired7
      tucexpired8
      tucexpired9
      tucexpired10
      tucexpired13
      tucdetail1
      tucdetail2
      tucdetail6
      tucdetail7
      createdAt
      updatedAt
    }
  }
`;
export const createTblItemSale = /* GraphQL */ `
  mutation CreateTblItemSale(
    $input: CreateTblItemSaleInput!
    $condition: ModelTblItemSaleConditionInput
  ) {
    createTblItemSale(input: $input, condition: $condition) {
      keypinID
      keypin
      tisid
      tiud
      tpatid
      tisrecipe
      tdocid
      tisdate
      tisnoinvoice
      tispredisc
      tisdisc
      tisdp
      tiscard
      tiscardcash
      tiscash
      tischange
      tistype
      tisnote
      tisactive
      tistaxinvoice
      tisdeadline
      tisday
      tispretax
      tistax
      tiscategoryid
      tiscost
      tshid
      tispay
      tissync
      tisbank
      tisbankid
      tistotal
      createdAt
      updatedAt
    }
  }
`;
export const updateTblItemSale = /* GraphQL */ `
  mutation UpdateTblItemSale(
    $input: UpdateTblItemSaleInput!
    $condition: ModelTblItemSaleConditionInput
  ) {
    updateTblItemSale(input: $input, condition: $condition) {
      keypinID
      keypin
      tisid
      tiud
      tpatid
      tisrecipe
      tdocid
      tisdate
      tisnoinvoice
      tispredisc
      tisdisc
      tisdp
      tiscard
      tiscardcash
      tiscash
      tischange
      tistype
      tisnote
      tisactive
      tistaxinvoice
      tisdeadline
      tisday
      tispretax
      tistax
      tiscategoryid
      tiscost
      tshid
      tispay
      tissync
      tisbank
      tisbankid
      tistotal
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblItemSale = /* GraphQL */ `
  mutation DeleteTblItemSale(
    $input: DeleteTblItemSaleInput!
    $condition: ModelTblItemSaleConditionInput
  ) {
    deleteTblItemSale(input: $input, condition: $condition) {
      keypinID
      keypin
      tisid
      tiud
      tpatid
      tisrecipe
      tdocid
      tisdate
      tisnoinvoice
      tispredisc
      tisdisc
      tisdp
      tiscard
      tiscardcash
      tiscash
      tischange
      tistype
      tisnote
      tisactive
      tistaxinvoice
      tisdeadline
      tisday
      tispretax
      tistax
      tiscategoryid
      tiscost
      tshid
      tispay
      tissync
      tisbank
      tisbankid
      tistotal
      createdAt
      updatedAt
    }
  }
`;
export const createTblItemSaleDetail = /* GraphQL */ `
  mutation CreateTblItemSaleDetail(
    $input: CreateTblItemSaleDetailInput!
    $condition: ModelTblItemSaleDetailConditionInput
  ) {
    createTblItemSaleDetail(input: $input, condition: $condition) {
      keypinID
      keypin
      tisdid
      tisid
      tiid
      tisdsoid
      tisdcode
      tisdtype
      tisdsatnama
      tisdqty
      tisdconvert
      tisdhb
      tisdhpp
      tisdhj
      tisdsubtotal
      tisdpredisc
      tisddisc
      tisdtotal
      tisdpackage
      tisdrecipe
      tisdtuslah
      tisdemba
      tisdnote
      tisdflax
      tisdflaxr
      tisdpriceid
      tisdpoint
      tisdname
      twhid
      tisdpricetype
      tisdsync
      createdAt
      updatedAt
    }
  }
`;
export const updateTblItemSaleDetail = /* GraphQL */ `
  mutation UpdateTblItemSaleDetail(
    $input: UpdateTblItemSaleDetailInput!
    $condition: ModelTblItemSaleDetailConditionInput
  ) {
    updateTblItemSaleDetail(input: $input, condition: $condition) {
      keypinID
      keypin
      tisdid
      tisid
      tiid
      tisdsoid
      tisdcode
      tisdtype
      tisdsatnama
      tisdqty
      tisdconvert
      tisdhb
      tisdhpp
      tisdhj
      tisdsubtotal
      tisdpredisc
      tisddisc
      tisdtotal
      tisdpackage
      tisdrecipe
      tisdtuslah
      tisdemba
      tisdnote
      tisdflax
      tisdflaxr
      tisdpriceid
      tisdpoint
      tisdname
      twhid
      tisdpricetype
      tisdsync
      createdAt
      updatedAt
    }
  }
`;
export const deleteTblItemSaleDetail = /* GraphQL */ `
  mutation DeleteTblItemSaleDetail(
    $input: DeleteTblItemSaleDetailInput!
    $condition: ModelTblItemSaleDetailConditionInput
  ) {
    deleteTblItemSaleDetail(input: $input, condition: $condition) {
      keypinID
      keypin
      tisdid
      tisid
      tiid
      tisdsoid
      tisdcode
      tisdtype
      tisdsatnama
      tisdqty
      tisdconvert
      tisdhb
      tisdhpp
      tisdhj
      tisdsubtotal
      tisdpredisc
      tisddisc
      tisdtotal
      tisdpackage
      tisdrecipe
      tisdtuslah
      tisdemba
      tisdnote
      tisdflax
      tisdflaxr
      tisdpriceid
      tisdpoint
      tisdname
      twhid
      tisdpricetype
      tisdsync
      createdAt
      updatedAt
    }
  }
`;
export const createMasObat = /* GraphQL */ `
  mutation CreateMasObat(
    $input: CreateMasObatInput!
    $condition: ModelmasObatConditionInput
  ) {
    createMasObat(input: $input, condition: $condition) {
      id
      obatid
      pabid
      katoid
      lokid
      obatkode
      obatnama
      obatjenis
      obatkandungan
      obatetiket
      obatsyarat
      createdAt
      updatedAt
    }
  }
`;
export const updateMasObat = /* GraphQL */ `
  mutation UpdateMasObat(
    $input: UpdateMasObatInput!
    $condition: ModelmasObatConditionInput
  ) {
    updateMasObat(input: $input, condition: $condition) {
      id
      obatid
      pabid
      katoid
      lokid
      obatkode
      obatnama
      obatjenis
      obatkandungan
      obatetiket
      obatsyarat
      createdAt
      updatedAt
    }
  }
`;
export const deleteMasObat = /* GraphQL */ `
  mutation DeleteMasObat(
    $input: DeleteMasObatInput!
    $condition: ModelmasObatConditionInput
  ) {
    deleteMasObat(input: $input, condition: $condition) {
      id
      obatid
      pabid
      katoid
      lokid
      obatkode
      obatnama
      obatjenis
      obatkandungan
      obatetiket
      obatsyarat
      createdAt
      updatedAt
    }
  }
`;
