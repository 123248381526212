import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { TblSync } from "../../models";
import { DataStore } from "@aws-amplify/datastore";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const Token$1 = "$nextToken: String";
const Token$2 = ", nextToken: $nextToken";
const limitQ = 9999999;
const Auth = () => {
  let user = JSON.parse(localStorage.getItem("user"));

  //---------Function Login----------//
  if (localStorage.getItem("user") !== null) {
    return {
      user: user,
      domain: user.domain,
      username: user.username,
      user_id: `${user.user_id}`,
      app_id: user.app_id,
      nama: user.nama_lengkap,
      apt_alamat: user.kl_alamat,
      apt_nama: user.kl_nama,
      apt_tlp: user.kl_tlp
    };
  }
};

//---------Function Logout----------//
export const Logout = props => {
  Swal.fire({
    title: "Anda Yakin Akan Logout?",
    text: "Anda Harus Login Kembali Jika Ingin Melanjutkan Penjualan.",
    icon: "question",
    showCancelButton: true,
    cancelButtonText: "Tidak",
    cancelButtonColor: "#e55353",
    confirmButtonText: "Ya, Lanjutkan",
    confirmButtonColor: "#2eb85c",
    // reverseButtons: true
  }).then(result => {
    if (result.isConfirmed) {
      // localStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("print");
      sessionStorage.removeItem("info");
      props.history.push("/login");
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
    }
  });
};

const CheckRoute = async () => {
  const Item = JSON.parse(localStorage.getItem("user"))?.app_id;
  if (!Auth() || !Item) {
    return "/login";
  } else {
    if (!(await CheckSyncData(Item))) {
      return `/${Auth().domain}/penjualan-obat-kasir-offline`;
      // return `/${Auth()}/sinkron-data`;
    } else {
      return `/${Auth().domain}/penjualan-obat-kasir-offline`;
    }
  }
};

const CheckSyncData = Item =>
  new Promise(async (resolve, reject) => {
    if (!!Item) {
      // Cek local
      const keypinData = await DataStore.query(TblSync, c =>
        c.keypin("eq", Item)
      );
      if (!!keypinData?.length > 0) {
        // Apakah user yg sama?
        const id = JSON.parse(localStorage.getItem("user"))?.id;
        const valUser = keypinData.lastItem.flaglocalid;
        // console.log(
        //   "valUser",
        //   valUser >= 0,
        //   typeof valUser?.length,
        //   typeof []?.length
        // );
        if (valUser?.length) {
          const inList = JSON.parse(valUser);
          const inListId =
            typeof inList?.length === "number" && inList?.indexOf(id) >= 0;
          // console.log(typeof inList, inListId);
          if (inListId) {
            resolve(inListId);
          } else {
            inList.push(id);
            const newList = JSON.stringify(inList);
            await DataStore.save(
              TblSync.copyOf(keypinData.lastItem, updated => {
                updated.flaglocalid = newList;
              })
            );
            resolve(!inListId);
          }
        } else {
          resolve(false);
        }
      }
      resolve(false);
    }
  });

const listTblSyncs = /* GraphQL */ `
  query ListTblSyncs(
    $filter: ModelTblSyncFilterInput
    $limit: Int
    Token$1
  ) {
    listTblSyncs(filter: $filter, limit: $limit Token$2) {
      nextToken
      items {
        id
        keypin
        tsylastdownload
        tsylastdownloadid
        tsylastupload
        tsylastuploadid
      }
    }
  }
`;

export { CheckRoute, CheckSyncData, Token$1, Token$2, limitQ };

export default Auth;
